@font-face {
  src:url(./fonts/Gigranche/OTF/Gigranche_Semi_Bold.otf);
  font-family: Gigranche_semi;
}

@font-face {
  src:url(./fonts/Gigranche/OTF/Gigranche_Regular.otf);
  font-family: Gigranche_regular;
}

@font-face {
  src:url(./fonts/Gangster_Grotesk/OTF/GangsterGrotesk-Regular.otf);
  font-family: GangsterGrotesk_regular;
}

@font-face {
  src:url(./fonts/Gangster_Grotesk/OTF/GangsterGrotesk-Bold.otf);
  font-family: GangsterGrotesk_bold;
}

@font-face {
  src:url(./fonts/hypercrack/OTF/Hypercrack.otf);
  font-family: Hypercrack;
}

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overscroll-behavior-y: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

html {
  cursor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue:hover{
  color: #30D5C8;
}

.black:hover{
  color: black;
}

.masonryGrid{
  display: flex;
  margin-left: -30px;
  width: 100%;
}

.masonryGridColumn{
  background-clip: padding-box;
  padding-left: 30px;
}

.masonryGridColumn:nth-child(2n) {
  padding-top: 7vh;
}
